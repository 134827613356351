export default (initialBasic = false) => ({
	basic: initialBasic,
	init() {
		this.template = this.$root.querySelector("template");
		this.formsContainer = this.$root.querySelector(".forms");
		this.totalFormsInput = this.$root.querySelector(
			`input[name$='-TOTAL_FORMS']`,
		);
	},
	addForm() {
		const newForm = this.template.content.cloneNode(true);
		// formn attributesa are zero indexed, so new form has the count value then we add
		const count = parseInt(this.totalFormsInput.value);

		// replace __prefix__ with the correct index
		for (let el of newForm.querySelectorAll("input, select, textarea")) {
			if (el.name.includes("__prefix__")) {
				el.name = el.name.replace("__prefix__", count);
			}
			if (el.id.includes("__prefix__")) {
				el.id = el.id.replace("__prefix__", count);
			}
		}
		const labels = newForm.querySelectorAll("label");
		for (let el of labels) {
			if (el.htmlFor.includes("__prefix__")) {
				el.htmlFor = el.htmlFor.replace("__prefix__", count);
			}
		}

		// add the new form to the dom
		this.formsContainer.appendChild(newForm);

		// adjust the management form inputs
		this.totalFormsInput.value = count + 1;
	},
	deleteForm() {
		let form = this.$el.closest(".form");
		if (this.basic) {
			form.remove();
			this.totalFormsInput.value = parseInt(this.totalFormsInput.value) - 1;
			const children = this.formsContainer.querySelectorAll(".form");
			for (let i = 0; i < children.length; i++) {
				const child = children[i];
				const inputs = child.querySelectorAll("input, select, textarea");
				for (let el of inputs) {
					el.name = el.name.replace(/\d+/, i);
					el.id = el.id.replace(/\d+/, i);
				}
				const labels = child.querySelectorAll("label");
				for (let el of labels) {
					el.htmlFor = el.htmlFor.replace(/\d+/, i);
				}
			}
		} else {
			form.querySelector("input[name$='-DELETE']").checked = true;
			form.classList.add("!hidden");
		}
	},
});
