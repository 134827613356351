function menuActions() {
	const $navTrigger = document.querySelector("[data-navigation-trigger]");
	const $navTarget = document.querySelector("[data-navigation-target]");
	const $mobileMenus = document.querySelectorAll("[data-mobile-submenu]");

	if ($navTrigger && $navTarget) {
		const $navLinks = $navTarget.querySelectorAll(
			"a:not([data-mobile-submenu-back])",
		);

		$navTrigger.addEventListener("click", (e) => {
			$navTrigger.classList.toggle("is-active");
			$navTarget.classList.toggle("translate-x-full");
			$navTarget.classList.add("duration-500"); // stop translate-x-full from animating on page load
			document.querySelector("html").classList.toggle("menu-open");
			linkTabIndex();

			$mobileMenus.forEach(($submenu) => {
				$submenu
					.querySelector("[data-mobile-submenu-list]")
					.classList.remove("is-active");
				$submenu.addEventListener("pointermove", preventDefault);
			});

			e.preventDefault();
		});

		$navLinks.forEach(($l) => {
			$l.addEventListener("click", (e) => {
				$navTrigger.classList.toggle("is-active");
				$navTarget.classList.toggle("translate-x-full");
				document.querySelector("html").classList.remove("menu-open");
			});
		});
	}

	if ($mobileMenus) {
		$mobileMenus.forEach(($submenu) => {
			const $trigger = $submenu.querySelector("[data-mobile-submenu-opener]");
			const $target = $submenu.querySelector("[data-mobile-submenu-list]");

			$trigger.addEventListener("click", (e) => {
				$target.classList.add("is-active");
				e.preventDefault();
			});

			$submenu
				.querySelector("[data-mobile-submenu-back]")
				.addEventListener("click", (e) => {
					$target.classList.remove("is-active");
					e.preventDefault();
				});
		});
	}

	const linkTabIndex = () => {
		if (window.innerWidth <= 1024) {
			document
				.querySelectorAll("[data-navigation-target] a")
				.forEach((link) => {
					link.tabIndex === 0 ? (link.tabIndex = -1) : (link.tabIndex = 0);
				});
		}
	};
	linkTabIndex();
}

export default menuActions;
