import { AjaxForm } from "@neonjungle/birdseed/forms/ajax";

class SearchAjaxForm extends AjaxForm {
	getFetchOptions(formMethod) {
		let fetchOptions = super.getFetchOptions(formMethod);
		fetchOptions.cache = "no-store";
		return fetchOptions;
	}

	setupListeners() {
		this.form.querySelectorAll("input, select").forEach((input) => {
			if (input.getAttribute("listening") !== "true") {
				if (
					input.getAttribute("type") === "date" &&
					navigator.userAgent.includes("Safari") &&
					!navigator.userAgent.includes("Chrome") &&
					window.innerWidth < 1024
				) {
					input.addEventListener("blur", (e) => this.validateAndTrigger(e));
				} else {
					input.addEventListener("change", (e) => this.validateAndTrigger(e));
				}
				input.setAttribute("listening", "true");
			}
		});

		let filterButton = document.querySelector("[data-filter-button]");

		filterButton.addEventListener(
			"click",
			function toggleFilters() {
				filterButton.querySelector(".arrow").classList.toggle("rotate-180");
				this.form.classList.toggle("hidden");
			}.bind(this),
		);
	}

	replaceTarget(html) {
		const parser = new DOMParser();
		const doc = parser.parseFromString(html, "text/html");
		const replacement = doc.querySelector("[data-ajax-target]");
		this.target.innerHTML = replacement.innerHTML;
		this.target = document.querySelector("[data-ajax-target]");
		this.form = document.querySelector("[data-search]");
		this.form.classList.remove("hidden");
		if (this.trigger) {
			this.trigger.focus();
		}
	}

	afterFetch() {
		document.querySelector("[data-search-q] input").value = "";
		this.setupListeners();
		super.afterFetch();
		const formData = new FormData(this.form);
		const parameters = Array.from(formData.entries())
			.map(([key, val]) => {
				if (val) {
					return `${key}=${encodeURIComponent(val)}`;
				}
			})
			.filter((val) => val)
			.join("&");
		const pushedURL = `${this.form.action}?${parameters}`;
		history.pushState({}, "", pushedURL);
	}

	validateAndTrigger(e) {
		if (e.target.checkValidity()) {
			this.fetchAndReplace();
			this.trigger = e.target;
		}
	}
}

export default () => {
	let searchForm = document.querySelector("[data-search]");
	if (searchForm) {
		new SearchAjaxForm(
			searchForm,
			document.querySelector("[data-ajax-target]"),
		);
		window.onpopstate = (e) => {
			// So that when the user hits back/forward the correct form + results are shown
			window.location.reload();
		};
	}
};
