import { JoinConfirmForm } from "../components/JoinConfirmForm";

export default () => {
	let joinForm = null;
	let target = document.querySelector("[data-join-confirm]");
	document.querySelectorAll("[data-join-select]").forEach((searchSelect) => {
		searchSelect.onActionPress = (resultNode) => {
			searchSelect.close();
			const data = JSON.parse(resultNode.getAttribute("data-value"));
			if (!joinForm) {
				joinForm = new JoinConfirmForm(
					data["object_id"],
					data["abn"],
					data["name"],
				);
				target.appendChild(joinForm);
			} else {
				for (let k in data) {
					joinForm.setAttribute(k, data[k]);
				}
			}
		};
		// option.addEventListener('click', e => {
		//     e.preventDefault();
		//     let data = JSON.parse(option.getAttribute('data-join'));
		//     if(!joinForm) {
		//         joinForm = new JoinConfirmForm(
		//             data['object_id'],
		//             data['abn'],
		//             data['name']
		//         )
		//         target.appendChild(joinForm)

		//     } else {
		//         for(let k in data) {
		//             joinForm.setAttribute(k, data[k]);
		//         }
		//     }
		// })
	});
};
