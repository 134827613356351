import anime from "animejs/lib/anime.es.js";

export class Carousel {
	constructor(carouselEl) {
		this.carouselEl = carouselEl;
		this.currentCard = 1;
		this.carouselLength = carouselEl.querySelectorAll(".carousel-card").length;
		this.curves = this.carouselEl.querySelector("#curves");
		carouselEl
			.querySelector(`#card-${this.currentCard}`)
			.classList.replace("translate-x-150", "duration-700");
		carouselEl.querySelectorAll(".next-btn").forEach((btn) => {
			btn.addEventListener("click", () => {
				this.toNextCard();
				this.circleTransition();
				this.preventMultipleClick(btn);
			});
		});
		carouselEl.querySelectorAll(".prev-btn").forEach((btn) => {
			btn.addEventListener("click", () => {
				this.toPrevCard();
				this.circleTransition();
				this.preventMultipleClick(btn);
			});
		});
		this.updateTabIndex();
	}

	toNextCard() {
		const card = this.carouselEl.querySelector(`#card-${this.currentCard}`);
		card.classList.toggle("scale-95");
		setTimeout(() => {
			card.classList.toggle("scale-95");
			card.classList.add("-translate-x-150");
			if (this.currentCard === this.carouselLength) {
				this.currentCard = 1;
				this.carouselEl
					.querySelectorAll(".carousel-card")
					.forEach((card) =>
						card.classList.replace("-translate-x-150", "translate-x-150"),
					);
			} else {
				this.currentCard++;
			}
			const current = this.carouselEl.querySelector(
				`#card-${this.currentCard}`,
			);
			current.classList.replace("translate-x-150", "duration-700");
			this.shrinkTransition(current);
			this.checkEndOfCarousel(card, current);
			this.updateTabIndex();
		}, 500);
	}

	toPrevCard() {
		const card = this.carouselEl.querySelector(`#card-${this.currentCard}`);
		card.classList.toggle("scale-95");
		setTimeout(() => {
			card.classList.toggle("scale-95");
			if (this.currentCard !== 1) {
				card.classList.add("translate-x-150");
				this.currentCard--;
			} else {
				card.classList.add("translate-x-150");
				this.currentCard = this.carouselLength;
				this.carouselEl
					.querySelectorAll(".carousel-card")
					.forEach((card) =>
						card.classList.replace("translate-x-150", "-translate-x-150"),
					);
			}
			const current = this.carouselEl.querySelector(
				`#card-${this.currentCard}`,
			);
			current.classList.replace("-translate-x-150", "duration-700");
			this.shrinkTransition(current);
			this.checkEndOfCarousel(card, current);
			this.updateTabIndex();
		}, 500);
	}

	checkEndOfCarousel(current_card, next_card) {
		let directions = ["left", "right"];
		let card_direction = Array.from(current_card.classList).find((cls) =>
			directions.includes(cls),
		);
		let current_direction = Array.from(next_card.classList).find((cls) =>
			directions.includes(cls),
		);
		if (window.innerWidth < 1024 || card_direction !== current_direction)
			this.curveTransition();
	}

	preventMultipleClick(btn) {
		btn.disabled = true;
		setTimeout(() => {
			btn.disabled = false;
		}, 800);
	}

	shrinkTransition(card) {
		card.classList.add("scale-95");
		setTimeout(() => {
			card.classList.remove("scale-95");
		}, 600);
	}

	curveTransition() {
		["rotate-180", "xl:right-[48%]", "xl:right-[-12%]"].map((classes) =>
			this.curves.classList.toggle(classes),
		);
	}

	circleTransition() {
		this.carouselEl.querySelectorAll(".circle").forEach((circle, index) => {
			circle.classList.remove("circle-transition");
			circle.classList.add("circle-transition");
			circle.classList.toggle("bg-white");
			circle.classList.toggle("bg-blue");
		});
		anime({
			targets: ".circle-transition",
			keyframes: [
				{
					translateX: function () {
						return anime.random(-1000, 1000);
					},
					easing: "cubicBezier(.5, .05, .1, .3)",
				},
				{
					translateY: function () {
						return anime.random(-200, 200);
					},
					easing: "cubicBezier(.5, .05, .1, .3)",
				},
				{ scale: 0 },
				{
					translateX: function () {
						return anime.random(-1000, 1000);
					},
					easing: "cubicBezier(.5, .05, .1, .3)",
				},
				{ scale: 1 },
				{ translateY: 0 },
				{ translateX: 0 },
			],
			duration: 1500,
			easing: "cubicBezier(.5, .05, .1, .3)",
		});
	}

	updateTabIndex() {
		this.carouselEl.querySelectorAll(".carousel-card").forEach((card) => {
			card.querySelectorAll("a, button").forEach((el) => {
				el.setAttribute("tabindex", "-1");
			});
		});
		const current = this.carouselEl.querySelector(`#card-${this.currentCard}`);
		current.querySelectorAll("a, button").forEach((el) => {
			el.setAttribute("tabindex", "0");
		});
	}
}
