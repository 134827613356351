class TextCarousel extends HTMLElement {
	connectedCallback() {
		this.trades = this.querySelectorAll("span");
		this.trades.forEach((trade) => {
			trade.classList.add(
				"hidden",
				"duration-1000",
				"transition-all",
				"opacity-0",
			);
		});
		this.setAttribute("data-current", 0);
		const textObserver = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						this.cycle();
					} else {
						clearTimeout(this.timer);
					}
				});
			},
			{ threshold: 1 },
		);
		textObserver.observe(this);
		this.timer;
	}

	static get observedAttributes() {
		return ["data-current"];
	}

	attributeChangedCallback(name, oldValue, newValue) {
		if (newValue >= 0) {
			this.current(parseInt(newValue));
		}
		if (oldValue !== null) {
			this.previous(parseInt(oldValue));
		}
	}

	cycle() {
		const current = parseInt(this.getAttribute("data-current"));
		if (current === this.trades.length - 1) {
			this.setAttribute("data-current", 0);
		} else {
			this.setAttribute("data-current", current + 1);
		}
		this.timer = setTimeout(() => {
			this.cycle();
		}, 3000);
	}

	current(current) {
		this.trades[current].classList.remove("hidden");
		setTimeout(() => {
			this.trades[current].classList.remove("opacity-0");
		}, 20);
	}

	previous(previous) {
		this.trades[previous].classList.add("hidden", "opacity-0");
	}
}

customElements.define("text-carousel", TextCarousel);
