export const dynamicValue = (f) => {
	const inputs = f.querySelectorAll("input, textarea");

	// Typing in an input or setting an el.value doesn't update the attribute in all browsers.
	// input[value=''] is a very useful CSS selector. This little snippet of code
	// makes sure the value attribute always matches the element.value -JS
	inputs.forEach(function (el) {
		el.setAttribute("value", el.value);
		el.setAttribute("size", 1);

		// We exclude date inputs as element.value will not begin to equal something
		// until the day month and year is specified, which overrides the user in chrome -CY
		if (!el.getAttribute("type")) {
			el.addEventListener("keyup", function (e) {
				el.setAttribute("value", el.value);
				e.preventDefault();
			});
		}
	});

	// Update file input value on change, useful in conjunction with CSS selector. -RdM
	const fileInputs = f.querySelectorAll('input[type="file"]');
	fileInputs.forEach((file) =>
		file.addEventListener("change", function (e) {
			let label = e.target.parentElement.parentElement.querySelector("label");
			if (!label) {
				return;
			}
			label.classList.remove("border-grey");
			label.classList.add("border-navy");
		}),
	);
};
